import { Injectable, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpBackend,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, finalize, map, share, tap } from "rxjs/operators";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { LoginFormErrorModalComponent } from "../shared/login-form-error-modal/login-form-error-modal.component";
import { ConformationModalComponent } from "../shared/conformation-modal/conformation-modal.component";
import { AccountService } from "./account.service";
import { BrowserLogger } from "./browser-logger.service";
import { RumLogger } from "./rum-logger.service";

import { Platform } from '@ionic/angular';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';

declare global {
  interface Window {
    FS: any;
  }
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  modalRef: BsModalRef;
  RumLoggerObj$: any = null;
  updatePwdUrl = `${environment.baseUrl}v1/authentication/updatePassword`;
  forgotPwdUrl = `${environment.baseUrl}v1/authentication/forgotPassword`;
  appName = environment.appName;

  //bypassing the global http interceptor
  //http: HttpClient;
  constructor(
    public appRate: AppRate, private platform: Platform,
    public accountService: AccountService,
    private router: Router,
    private http: HttpClient,
    private actvatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    handler: HttpBackend
  ) {
    this.loginurl = window.localStorage.getItem("loginurl");
    //bypassing the global http interceptor
    //this.http = new HttpClient(handler);


  }
  loggedInEvent = new EventEmitter();
  loginError = new BehaviorSubject(false);
  loginurl: string;
  currentPath: string;
  loggedIn() {
    this.loggedInEvent.emit(true);
  }
  loggedOut() {
    this.loggedInEvent.emit(false);
  }

  private cache: any;
  private cachedObservable: Observable<any>;

  isTokenIdValid(): Observable<any> {
    let observable: Observable<any>;
    const id_token = window.localStorage.getItem('id_token');
    const appName = environment.appName;

    // const id_token = "";
    // const headers = { Authorization: "Bearer " + id_token, appName: appName };
    const headers = { 'Authorization': 'Bearer ' + id_token, "appName": appName, 'skip_interceptor': 'false' }
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    this.cachedObservable = this.http
      .get(`${this.loginurl}v1/authentication/validateToken`, {
        headers,
      })
      .pipe(
        tap((res) => (this.cache = res)),
        share(),
        finalize(() => (this.cachedObservable = null))
      );
    observable = this.cachedObservable;

    return observable;
  }
  isIframeTokenIdValid(id_token, appName) {
    return new Promise((resove, rej) => {
      const headers = new HttpHeaders()
        .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
        .append('appname', appName)
        .append("Authorization", "Bearer " + id_token)
        .append("skip_interceptor", 'true')
        .append("skip_interceptor_rum_logs", 'true');
      this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers }).toPromise().then((res) => {
        var loginurl = environment.baseUrl;
        const headers = { 'Authorization': 'Bearer ' + id_token, "appName": appName, skip_interceptor: "true" }
        this.http.get(`${loginurl}v1/authentication/validateToken`, {
          headers
        }).subscribe((res) => { resove(res) }, () => { rej() })
      })
    });
  }
  siteMetaData() {
    return new Promise((resove, rej) => {
      const headers = { skip_interceptor: "true" }
      this.http.get(`${environment.baseUrl}v1/revenued/getSiteMetaData`, {
        headers
      }).toPromise().then((res) => {
        localStorage.setItem('LANDING_PAGE_DESCRIPTION', res['LANDING_PAGE_DESCRIPTION'] || 'Revenued offers a FlexLine + Card built for all businesses');
        localStorage.setItem('ReferAFriendUrl', res['ReferAFriendUrl'] || 'https://info.revenued.com/apply?refsource=ReferralProgram&subrefsource=ReferralProgram&refId=####REFID####');

      }).catch((error) => {
        console.log(error);
        localStorage.setItem('LANDING_PAGE_DESCRIPTION', 'Revenued offers a FlexLine + Card built for all businesses');
        localStorage.setItem('ReferAFriendUrl', 'https://info.revenued.com/apply?refsource=ReferralProgram&subrefsource=ReferralProgram&refId=####REFID####');

      })
    });
  }
  getConfigs() {
    let appName = environment.appName;
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    const headers = new HttpHeaders()
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname', appName)
      .append("Authorization", "Bearer " + idToken)
      .append("skip_interceptor", 'true')
      .append("skip_interceptor_rum_logs", 'true');
    this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers }).toPromise().then((res) => {
      var loginurl = environment.baseUrl;
      window.localStorage.setItem("loginError", '');
      window.localStorage.setItem('KYC_IFRAME_URL', res['KYC_IFRAME_URL']);
      window.localStorage.setItem("ENABLE_GLOBAL_HTTP_INTERCEPTOR", res["ENABLE_GLOBAL_HTTP_INTERCEPTOR"]);
      window.localStorage.setItem("DATADOG_OPTION_SAMPLE_RATE", res["DATADOG_OPTION_SAMPLE_RATE"]);
      window.localStorage.setItem("ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING", res["ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING"]);
      window.localStorage.setItem("DATADOG_HOST_NAME", res["DATADOG_HOST_NAME"]);

      window.localStorage.setItem("DISABLED_DRAW", res['DISABLED_DRAW']);
      window.localStorage.setItem("DISABLED_PREPAY", res['DISABLED_PREPAY']);
      window.localStorage.setItem("DISABLED_PAYMENT_ADJUSTMENT", res['DISABLED_PAYMENT_ADJUSTMENT']);
      window.localStorage.setItem("DISABLED_REWARDS", res['DISABLED_REWARDS']);
      this.appName = environment.appName;
      window.localStorage.setItem("appName", this.appName);
      window.localStorage.setItem("COVERDASH_LICENSE", res['COVERDASH_LICENSE']);
      window.localStorage.setItem("COVERDASH_QUOTES_URL", res['COVERDASH_QUOTES_URL']);
      this.loginurl = loginurl;
    });
  }

  cacheData = undefined;

  fetchUserCache(email, token) {
    email = email.toLowerCase().trim();
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    const headers = new HttpHeaders()
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname', environment.appName)
      .append("Authorization", "Bearer " + idToken)
      .append("skip_interceptor", 'true')
      .append("skip_interceptor_rum_logs", 'true');
    this.http.post(`${environment.baseUrl}v1/cache/getUserCacheData`, { email, token }, { headers })
      .subscribe((res: any) => {
        if (res) {
          if (res.status === 'success') {
            if (res.data) {
              this.cacheData = res.data;
              this.accountService.updateAccountStatementState(this.cacheData.spendingLimit, this.cacheData.discountedBalance, this.cacheData.pendingAmount, this.cacheData.spendingAvailability, this.cacheData.dailySpendLimit, this.cacheData.ministatement, this.cacheData.currentTransactions, this.cacheData.paymentCaledarHistoryData);
            }
          }
        }
      },
        (err) => {
          console.log(err);
        }
      );
  }

  BrowserLoggerObj = null;
  RumLoggerObj = null;
  appLogin(cred, failed, loggedIn, redirectURL, isOtpLogin = false, isMFAOtpLogin = false) {


    this.appName = environment.appName;
    this.loginurl = environment.baseUrl;
    const headers = { 'appName': this.appName, skip_interceptor: "true" };


    let loginLink = this.loginurl + "v1/authentication/loginUser";
    if (isOtpLogin) {
      loginLink = this.loginurl + "v1/authentication/verifyEmailOtp";
    }

    if (isMFAOtpLogin) {
      loginLink = this.loginurl + "v1/authentication/verifyMFAChallengeToken";
    }
    this.http.post(`${loginLink}`, { ...cred.value, email: cred.value.email.trim() }, { headers })
      .subscribe((res) => {
        let appName = environment.appName;
        var idToken = res['id_token'];

        const headers = new HttpHeaders()
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
          .append('appname', appName)
          .append("Authorization", "Bearer " + idToken)
          .append("skip_interceptor", 'true')
          .append("skip_interceptor_rum_logs", 'true');
        this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers }).toPromise().then((res) => {
          var loginurl = this.loginurl;
          window.localStorage.setItem("loginError", '');
          window.localStorage.setItem('KYC_IFRAME_URL', res['KYC_IFRAME_URL']);
          window.localStorage.setItem("ENABLE_GLOBAL_HTTP_INTERCEPTOR", res["ENABLE_GLOBAL_HTTP_INTERCEPTOR"]);
          window.localStorage.setItem("DATADOG_OPTION_SAMPLE_RATE", res["DATADOG_OPTION_SAMPLE_RATE"]);
          window.localStorage.setItem("ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING", res["ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING"]);
          window.localStorage.setItem("DATADOG_HOST_NAME", res["DATADOG_HOST_NAME"]);

          window.localStorage.setItem("DISABLED_DRAW", res['DISABLED_DRAW']);
          window.localStorage.setItem("DISABLED_PREPAY", res['DISABLED_PREPAY']);
          window.localStorage.setItem("DISABLED_PAYMENT_ADJUSTMENT", res['DISABLED_PAYMENT_ADJUSTMENT']);
          window.localStorage.setItem("DISABLED_REWARDS", res['DISABLED_REWARDS']);
          window.localStorage.setItem("COVERDASH_LICENSE", res['COVERDASH_LICENSE']);
          window.localStorage.setItem("COVERDASH_QUOTES_URL", res['COVERDASH_QUOTES_URL']);
          window.localStorage.setItem("PAYMENT_CALENDER_INFO_TEXT", res['PAYMENT_CALENDER_INFO_TEXT']);
        });


        let configs = { ...res };
        if (res['access_token']) {
          const headers = { 'Authorization': 'Bearer ' + res['id_token'], 'appName': this.appName };
          window.localStorage.setItem('loginurl', this.loginurl)
          window.localStorage.setItem('id_token', res['id_token'])

          this.http.post(`${this.loginurl}v1/authentication/getUserDetails`, { email: cred.value.email.trim() }, {
            headers
          }).subscribe((details) => {


            /*sets user data to rum logs*/


            try {
              this.BrowserLoggerObj = new BrowserLogger();
              this.RumLoggerObj = new RumLogger();
              this.RumLoggerObj$ = this.RumLoggerObj;
            } catch (error) {
              //do nothing
            }

            try {
              if (this.RumLoggerObj$) {
                this.RumLoggerObj$.setCustomUser({
                  "displayName": details['name'],
                  "email": details['email'],
                  "appId": details['app_metadata'].merchAppId,
                  "oppId": details['app_metadata'].opportunityId,
                  "cardId": details['app_metadata'].cardId,
                  "name": details['name'],
                  "id": details['app_metadata'].opportunityId,
                  "platform": (window.localStorage.getItem('platform') || 'browser')
                });


                this.RumLoggerObj$.setCustomAction(JSON.stringify({
                  "displayName": details['name'],
                  "email": details['email'],
                  "appId": details['app_metadata'].merchAppId,
                  "oppId": details['app_metadata'].opportunityId,
                  "cardId": details['app_metadata'].cardId,
                  "name": details['name'],
                  "id": details['app_metadata'].opportunityId,
                  "platform": (window.localStorage.getItem('platform') || 'browser')
                }), {
                  "displayName": details['name'],
                  "email": details['email'],
                  "appId": details['app_metadata'].merchAppId,
                  "oppId": details['app_metadata'].opportunityId,
                  "cardId": details['app_metadata'].cardId,
                  "name": details['name'],
                  "id": details['app_metadata'].opportunityId,
                  "platform": (window.localStorage.getItem('platform') || 'browser')
                });


              }
            } catch (error) {
              //do nothing
            }
            /*sets user data to rum logs*/



            window.localStorage.setItem('oppId', details['app_metadata'].opportunityId)
            window.localStorage.setItem('displayName', details['name'])
            window.localStorage.setItem('login_ts', new Date().getTime().toString());
            window.localStorage.setItem('email', details['email'])
            window.localStorage.setItem('name', details['name'])
            window.localStorage.setItem('cardId', details['app_metadata'].cardId)
            window.localStorage.setItem('coverdashBusinessId', details['app_metadata'].coverdashBusinessId ?? '')
            window.localStorage.setItem('contId', details['app_metadata'].contId)
            window.localStorage.setItem('appId', details['app_metadata'].merchAppId)
            window.localStorage.setItem('logins_count', details['logins_count'] ? details['logins_count'].toString() : 'NA')
            window.localStorage.setItem('accountOwner', 'true');
            if (configs['Grace_Window_Days']) {
              window.localStorage.setItem('Grace_Window_Days', configs['Grace_Window_Days']);
            }
            if (configs['Post_Grace_Premium']) {
              window.localStorage.setItem('Post_Grace_Premium', configs['Post_Grace_Premium']);
            }
            if (details["app_metadata"].isOperator) {
              window.localStorage.setItem("userMode", "operator");
            } else {
              window.localStorage.setItem("userMode", "general");
            }
            window.localStorage.setItem("token", JSON.stringify(res));
            window.localStorage.setItem("appName", this.appName);


            /*cache implementation*/
            if (cred.value.email.trim() && res['access_token'])
              this.fetchUserCache(cred.value.email.trim(), res['access_token']);
            /*cache implementation*/




            this.accountService.getAccountSummary().subscribe((res) => {

              try {
                res = JSON.parse(res);
                let isCollection = res.RevAccountSummary.summary?.inCollection || 'false';
                if (isCollection === 'true') {
                  this.router.navigate(['/dashboard/collection']);
                } else {

                  let platformName = window.localStorage.getItem('platform') || 'browser';

                  if (redirectURL && platformName !== 'Android' && platformName !== 'iOS') {
                    if (redirectURL === '/pricing') {
                      this.router.navigate(['/dashboard/prepay-cal']);
                    } else {
                      this.router.navigate([redirectURL]);
                    }
                  } else {
                    if (localStorage.getItem('skipIntro') && localStorage.getItem('skipIntro') !== "true" && localStorage.getItem('environment') !== 'web' && localStorage.getItem('environment')) {
                      console.log(localStorage.getItem('skipIntro'))
                      this.router.navigate(['/walkthrough'])
                    } else {
                      this.router.navigate(["/dashboard"]);
                    }
                  }

                }
              } catch (error) {
                this.router.navigate(["/dashboard"]);
              }

            }, error => {
              this.router.navigate(["/dashboard"]);
            });

          })
        } else {
          console.log("Errro");
          failed = true;
          loggedIn = false;
          this.loginError.next(true);
        }
      },
        (err) => {

          if (err.error.error === 'mfa_required') {
            window.localStorage.setItem('mfa_token', err.error.mfa_token);
            window.localStorage.setItem('oob_code', err.error.oob_code)
            this.router.navigate(["/mfaotplogin", cred.value.email.trim(), true]);
            return;
          }

          failed = true;
          loggedIn = false;
          let errMsg = "Something went wrong!"
          if (err.error.errorMessage) {
            errMsg = err.error.errorMessage
          } else if (err.error.error) {
            errMsg = err.error.error
          }
          window.localStorage.setItem("loginError", errMsg);
          this.loginError.next(true);
        }
      )



  }
  getClientEmails(body, headers) {
    //handle-from-global-interceptor
    return this.http.post(
      `${window.localStorage.getItem("loginurl")}v1/authentication/getClientEmails`,
      body,
      {
        headers,
      }
    );
  }
  getUsersOfOperator(body, headers) {
    //handle-from-global-interceptor
    return this.http.post(`${environment.baseUrl}v1/authentication/getUsersOfOperator`, body, {
      headers,
    });
  }
  updatePwd(cred) {
    const appName = environment.appName;
    const headers = { skip_interceptor: "true", appname: appName };
    //handle-from-global-interceptor
    return this.http.post(this.updatePwdUrl, cred, {
      headers,
    });
  }
  updateMetadata(data) {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
      skip_interceptor: "true",
    };
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post<{ results: any[] }>(`${this.loginurl}v1/authentication/updateUserMetaData`, data, {
        headers,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
  // setPwd(pwd){
  //   console.log("New created password details::",pwd)
  //   return this.http.post(this.updatePwdUrl,pwd);
  // }
  forgotPwd(email) {
    email.email = email.email.toLowerCase().trim()
    const headers = { appName: this.appName, skip_interceptor: "true" };
    //handle-from-global-interceptor
    return this.http.post(`${environment.baseUrl}v1/authentication/forgotPassword`, email, {
      headers,
    });
  }

  sendEmailOtp(email) {
    let data = {
      email: email.toLowerCase().trim()
    }
    const headers = { appName: this.appName, skip_interceptor: "true" };
    return this.http.post(`${environment.baseUrl}v1/authentication/sendEmailOtp`, data, {
      headers,
    });
  }

  checkPasswordToken(details, path) {

    this.currentPath = path;
    const headers = { appname: environment.appName, skip_interceptor: 'true' };
    // const headers = { skip_interceptor: "true" };
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post(`${environment.baseUrl}v1/authentication/validateEmailVerificationToken`, details, {
        headers,
      })
      .pipe(
        catchError(this.handleError.bind(this)),
        map((res) => res)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // alert("Please check your Internet Connection. Something went wrong")
      const initialState = {
        title: "Error",
        message: "Please check your Internet Connection. Something went wrong",
      };
      this.openModal(initialState);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if (this.currentPath === "verifyEmail") {
        // alert("Password has been created or link has expired. Please contact Revenued Support.")
        const initialState = {
          title: "",
          message:
            "Password has been created or link has expired. Please contact Revenued Support.",
        };
        this.openModal(initialState);
      }
      if (this.currentPath === "passwordReset") {
        // alert("Link has expired. Please request another reset link using Forgot Password.")
        const initialState = {
          title: "",
          message:
            "Link has expired. Please request another reset link using Forgot Password.",
        };
        this.openModal(initialState);
      }

      // this.router.navigate(["/login"]);
      // console.log(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      error.error ||
      error.message ||
      "Something bad happened; please try again later."
    );
  }
  openModal(initialState) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(LoginFormErrorModalComponent, {
      initialState,
    });
    this.modalRef.content.closeBtnName = "Close";
  }
  openConformationModalAndLogout() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(ConformationModalComponent);
    this.modalRef.content.closeBtnName = "Close";
    this.modalRef.content.onClose.subscribe((result) => {
      console.log("results", result);
      if (result) {

        this.platform.ready().then(() => {

          if (this.platform.is('cordova')) {

            this.appRate.setPreferences({
              displayAppName: 'Revenued App',
              usesUntilPrompt: 2,
              promptAgainForEachNewVersion: false,
              storeAppURL: {
                ios: environment.appstoreAppId,
                android: environment.playstoreAppUrl
              },
              customLocale: {
                title: 'Do you enjoy %@?',
                message: 'If you enjoy using %@, would you mind taking a moment to rate it? Thanks so much!',
                cancelButtonLabel: 'No, Thanks',
                laterButtonLabel: 'Remind Me Later',
                rateButtonLabel: 'Rate It Now'
              },
              callbacks: {
                onRateDialogShow: function (callback) {
                  console.log('rate dialog shown!');
                },
                onButtonClicked: function (buttonIndex) {
                  console.log('Selected index: -> ' + buttonIndex);
                }
              }
            });

            // Opens the rating immediately no matter what preferences you set
            this.appRate.promptForRating(true);

          }

        });

        var skipIntro = localStorage.getItem('skipIntro');
        var platform = localStorage.getItem('platform');
        var environment1 = localStorage.getItem('environment');
        var platformAppName = localStorage.getItem('platform_app_name');
        var platformAppPackageName = localStorage.getItem('platform_app_package_name');
        var platformAppVersionCode = localStorage.getItem('platform_app_version_code');
        var platformAppVersionNumber = localStorage.getItem('platform_app_version_number');
        var landingPageDescription = localStorage.getItem('LANDING_PAGE_DESCRIPTION') || 'Revenued offers a FlexLine + Card built for all businesses';
        var referAFriendUrl = localStorage.getItem('ReferAFriendUrl') || "https://info.revenued.com/apply?refsource=ReferralProgram&subrefsource=ReferralProgram&refId=####REFID####";



        //tbd
        this.accountService.logoutUser().subscribe({
          next: () => {},
          error: () => {},
          complete:()=>{
            window.localStorage.clear();
          }
        })

        if (skipIntro) {
          localStorage.setItem('skipIntro', 'true');
        }
        if (environment) {
          localStorage.setItem('environment', environment1);
        }
        if (platform) {
          localStorage.setItem('platform', platform);
        }
        if (platformAppName) {
          localStorage.setItem('platform_app_name', platformAppName);
        }
        if (platformAppPackageName) {
          localStorage.setItem('platform_app_package_name', platformAppPackageName);
        }
        if (platformAppVersionCode) {
          localStorage.setItem('platform_app_version_code', platformAppVersionCode);
        }
        if (platformAppVersionNumber) {
          localStorage.setItem('platform_app_version_number', platformAppVersionNumber);
        }

        if (landingPageDescription) {
          localStorage.setItem('LANDING_PAGE_DESCRIPTION', landingPageDescription);
        }

        if (referAFriendUrl) {
          localStorage.setItem('ReferAFriendUrl', referAFriendUrl);
        }

        // window.localStorage.removeItem('token');
        this.loginError.next(false);
        this.router.navigate(["/login"]);
      }
    });
  }


  checkForMaintainanceMode() {

    const headers = { skip_interceptor: "true" };
    return this.http
      .get(`${environment.appUrl}assets/status.json`, {
        headers,
      });
  }

  checkAppSupportedVersions(deviceType) {

    let url = `${environment.baseUrl}v1/authentication/getAllSupportedAndroidVersions`;
    if (deviceType === 'iOS') {
      url = `${environment.baseUrl}v1/authentication/getAllSupportediOSVersions`;
    }

    const headers = { skip_interceptor: "true" };
    return this.http
      .get(url, {
        headers,
      });
  }

  shouldShowDuns() {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const email = window.localStorage.getItem('email');

    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
      skip_interceptor: "true",
    };
    //handle-from-global-interceptor
    return this.http.post(`${environment.baseUrl}v1/authentication/issetDuns`, { email },
      { headers },
    )
  };

  updateDuns() {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
      skip_interceptor: "true",
    };
    //handle-from-global-interceptor
    return this.http.get(`${environment.baseUrl}v1/authentication/updateDuns?accId=0010y00001iJIuaAAG&duns=05508053&email=testuser.revenued@gmail.com`, {
      headers,
    });
  }
}
