import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpBackend,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, of, throwError } from "rxjs";
import { Observable } from "rxjs";
import { catchError, finalize, share, tap } from "rxjs/operators";
import * as moment from "moment-timezone";
import { CurrencyPipe } from "@angular/common";
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: "root",
})
export class AccountService {
  globalDataLoader: boolean = false;
  hideRewards: boolean = true;
  //to  use of rxjs/behaviorsubject
  showNotifsCount = false;
  accountStatementState = {
    spendingLimit: undefined,
    discountedBalance: undefined,
    pendingAmount: undefined,
    spendingAvailability: undefined,
    dailySpendLimit: undefined,
    ministatement: [],
    currentTransactions: [],
    paymentCaledarHistoryData: undefined
  }
  updateAccountStatementState(spendingLimit = undefined, discountedBalance = undefined, pendingAmount = undefined, spendingAvailability = undefined, dailySpendLimit = undefined, ministatement = [], currentTransactions = [], paymentCaledarHistoryData = undefined) {
    //alias names/variables
    // availableCredit == spendingAvailability ->res.RevAccountSummary.limit.maxFundLimit
    // curentBalance == discountedBalance -> res.RevAccountSummary.summary.payNowBal
    this.accountStatementState = {
      spendingLimit: spendingLimit,
      discountedBalance: discountedBalance,
      pendingAmount: pendingAmount,
      spendingAvailability: spendingAvailability,
      dailySpendLimit: dailySpendLimit,
      ministatement: ministatement,
      currentTransactions: currentTransactions,
      paymentCaledarHistoryData: paymentCaledarHistoryData
    }
    this.globalDataLoader = false;
    return this.accountStatementState;
  }


  unsetAccountStatementState() {
    this.accountStatementState = {
      spendingLimit: undefined,
      discountedBalance: undefined,
      pendingAmount: undefined,
      spendingAvailability: undefined,
      dailySpendLimit: undefined,
      ministatement: [],
      currentTransactions: [],
      paymentCaledarHistoryData: undefined
    }
    this.unsetCacheData().subscribe(
      (res) => {
        console.log("cache unset success!");
      },
      () => {
        console.log("cache unset failed!");
      }
    );

    this.globalDataLoader = false;
    return this.accountStatementState;
  }

  getRandom() {
    return Math.floor(Math.random() * 1000000000);
  }

  getFormattedAmount(amount) {
    return this.currencyPipe.transform(amount || 0, "$");
  }
  getTitleForHistory(arg: any): string {
    if (arg && arg.pmtCode) {
      if (arg.pmtCode === "I") {
        return "Invalid";
      } else if (arg.pmtCode === "P") {
        return "Paid";
      } else if (arg.pmtCode === "U") {
        return "Uncleared";
      } else if (arg.pmtCode === "K") {
        return "Revoked";
      } else if (arg.pmtCode === "A") {
        return "Adjustment";
      } else if (arg.pmtCode === "L") {
        return "Partial";
      } else if (arg.pmtCode === "B") {
        return "Bounced";
      } else if (arg.pmtCode === "F") {
        return "MiscFee";
      } else if (arg.pmtCode === "E") {
        return "Error";
      } else if (arg.pmtCode === "S") {
        return "Suspended";
      } else if (arg.pmtCode === "C") {
        return "Collection";
      } else if (arg.pmtCode === "N") {
        return "Note";
      } else if (arg.pmtCode === "T") {
        return "Task";
      } else if (arg.pmtCode === "X") {
        return "Uncleared PayNow";
      } else if (arg.pmtCode === "Y") {
        return "Paid";
      } else if (arg.pmtCode === "D") {
        return "MCADraw";
      } else if (arg.pmtCode === "R") {
        return "Refund";
      } else if (arg.pmtCode === "W") {
        return "Rewards";
      } else {
        return "NA";
      }
    } else {
      return "NA";
    }
  }
  checkIsVisible(arg: any): boolean {
    let isVisible = true;
    if (arg && arg.pmtCode) {
      if (arg.pmtCode === "U") {
        isVisible = false;
      } else if (arg.pmtCode === "D") {
        isVisible = false;
      } else if (arg.pmtCode === "N") {
        isVisible = false;
      } else if (arg.pmtCode === "T") {
        isVisible = false;
      } else if (arg.pmtCode === "X") {
        isVisible = false;
      } else if (arg.pmtCode === "W") {
        isVisible = false;
      }
    }
    return isVisible;
  }

  getIsVisibleCount(arr: any) {
    let isVisibleCount = 0;

    try {
      arr.forEach(arg => {
        if (arg && arg.pmtCode) {
          if (arg.pmtCode === "U") {
          } else if (arg.pmtCode === "D") {

          } else if (arg.pmtCode === "N") {

          } else if (arg.pmtCode === "T") {

          } else if (arg.pmtCode === "X") {

          } else if (arg.pmtCode === "W") {

          } else {
            //hehehe
            isVisibleCount++;
          }
        }
      });
    } catch (error) {
      //do-nothing
    }

    return isVisibleCount;
  }

  setCacheData(data: any): any {
    // localStorage.setItem("cacheData", JSON.stringify(data));
    let postData = {
      email: window.localStorage.getItem("email"),
      token: JSON.parse(window.localStorage.getItem("token")).access_token,
      data: data
    }
    /*cache implementation*/
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    return this.http
      .post(`${environment.baseUrl}v1/cache/setUserCacheData`, postData, {

        headers: new HttpHeaders()
          // .set("skip_interceptor", "true")
          .set("skip_interceptor_rum_logs", "true")
          .append("appname", environment.appName)
          .append("Access-Control-Allow-Methods", "POST")
          .append("Access-Control-Allow-Origin", "*")
          .append("Authorization", "Bearer " + idToken)
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          )
      });

    /*cache implementation*/
  }

  unsetCacheData(): any {
    // localStorage.setItem("cacheData", JSON.stringify(data));
    let postData = {
      email: window.localStorage.getItem("email"),
      token: JSON.parse(window.localStorage.getItem("token")).access_token,
      data: " "
    }
    /*cache implementation*/
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    return this.http
      .post(`${environment.baseUrl}v1/cache/unsetUserCacheData`, postData, {
        headers: new HttpHeaders()
          // .set("skip_interceptor", "true")
          .set("skip_interceptor_rum_logs", "true")
          .append("appname", environment.appName)
          .append("Access-Control-Allow-Methods", "POST")
          .append("Access-Control-Allow-Origin", "*")
          .append("Authorization", "Bearer " + idToken)
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          )
      });

    /*cache implementation*/
  }
  //to  use of rxjs/behaviorsubject

  private cache: any;
  private cachedObservable: Observable<any>;

  //to bypass the global http interceptor
  //http: HttpClient;
  constructor(handler: HttpBackend, private http: HttpClient,

    private currencyPipe: CurrencyPipe,
    private toastr: ToastrService) {
    //to bypass the global http interceptor
    // this.http = new HttpClient(handler);
  }
  searchTransactionsEvent = new BehaviorSubject("");
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    console.log(error.message);
    // Return an observable with a user-facing error message.
    return throwError(
      error.error ||
      error.message ||
      "Something bad happened; please try again later."
    );
  }
  searchTransactions(searchKey) {
    this.searchTransactionsEvent.next(searchKey);
  }
  getSummary() {
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get("/assets/summary.xml", {
        headers: new HttpHeaders()
          .set("Content-Type", "text/xml")
          // .append("skip_interceptor", "true")
          .append("Access-Control-Allow-Methods", "GET")
          .append("Access-Control-Allow-Origin", "*")
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          ),
        responseType: "text",
      })
      .pipe(catchError(this.handleError));
  }

  getAccountSummary(): any {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get(`${environment.baseUrl}v1/revenued/accountsummary/${oppId}`, {
        headers: new HttpHeaders()
          .set("Content-Type", "text/xml")
          // .append('skip_interceptor', 'false')
          .append("Access-Control-Allow-Methods", "GET")
          .append("Access-Control-Allow-Origin", "*")
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          )
          .append("appname", environment.appName)
          .append("Authorization", "Bearer " + idToken),
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }
  getTransactionHistory(): any {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get(`${environment.baseUrl}v1/revenued/transactionhistory/${oppId}`, {
        headers: new HttpHeaders()
          .set("Content-Type", "text/xml")
          .append('skip_interceptor', 'true')
          .append("Access-Control-Allow-Methods", "GET")
          .append("Access-Control-Allow-Origin", "*")
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          )
          .append("appname", environment.appName)
          .append("Authorization", "Bearer " + idToken),
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }


  getTransactionDetails(tid, oppId, idToken, appname): any {

    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get(`${environment.baseUrl}v1/revenued/transactiondetails/${oppId}?tid=` + tid, {
        headers: new HttpHeaders()
          .set("Content-Type", "text/xml")
          // .append('skip_interceptor', 'false')
          .append("Access-Control-Allow-Methods", "GET")
          .append("Access-Control-Allow-Origin", "*")
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          )
          .append("appname", appname)
          .append("Authorization", "Bearer " + idToken),
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }
  getPaymentDetails(pid, oppId, idToken, appname): any {
    return this.http
      .get(`${environment.baseUrl}v1/revenued/paymentdetails/${oppId}?pid=` + pid, {
        headers: new HttpHeaders()
          .set("Content-Type", "text/xml")
          // .append('skip_interceptor', 'false')
          .append("Access-Control-Allow-Methods", "GET")
          .append("Access-Control-Allow-Origin", "*")
          .append(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
          )
          .append("appname", appname)
          .append("Authorization", "Bearer " + idToken),
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }
  getTransactionHistoryNew(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    filters: string
  ): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const body = {
      pageNo: pageIndex,
      pageRows: pageSize,
      sortFilter: sortField,
      whereClause: filters,
    };
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("pageNo", pageIndex ? `${pageIndex}` : "")
      .append("pageRows", pageSize ? `${pageSize}` : "")
      .append("sortFilter", sortField ? `${sortField}` : "")
      .append("whereClause", filters ? `${filters}` : "");

    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/transactionhistory/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getTransactionHistoryIframe(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    filters: string,
    oppId: string,
    idToken: string,
    appname: string
  ): Observable<any> {
    const body = {
      pageNo: pageIndex,
      pageRows: pageSize,
      sortFilter: sortField,
      whereClause: filters,
    };
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "true")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", appname)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("pageNo", pageIndex ? `${pageIndex}` : "")
      .append("pageRows", pageSize ? `${pageSize}` : "")
      .append("sortFilter", sortField ? `${sortField}` : "")
      .append("whereClause", filters ? `${filters}` : "");
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/transactionhistory/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getPaymentCalendarRecords(): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'false')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();

    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getPaymentCalendar/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getPaymentCalendarHistory(begDate: string, endDate: string): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'false')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("begDate", begDate)
      .append("endDate", endDate);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getPaymentCalendarHistory/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }
  getPaymentCalendarHistoryIframe(begDate: string, endDate: string, oppId, idToken, appname): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'false')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", appname)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("begDate", begDate)
      .append("endDate", endDate);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getPaymentCalendarHistory/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }
  requestRefiTerms(cardId: string, amount: string, caseId: string): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "false")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/requestRefiTerms/${oppId}`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  requestRefi(cardId: string, amount: string, caseId: string): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "false")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/requestRefi/${oppId}`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  requestDraw(cardId: string, amount: string, caseId: string): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "false")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/requestDraw/${oppId}`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  createCase(data) {
    var oppId = window.localStorage.getItem("oppId");
    var submitter = window.localStorage.getItem("email");
    var idToken =
      window.localStorage.getItem("token") &&
        JSON.parse(window.localStorage.getItem("token"))
        ? JSON.parse(window.localStorage.getItem("token")).id_token
        : "";
    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
    };
    return this.http
      .post<{ results: any[] }>(
        `${environment.baseUrl}v1/logging/createCase`,
        { ...data, submitter, recordId: oppId, source: "Account Portal" },
        { headers }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateCase(data) {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
    };

    return this.http
      .post<{ results: any[] }>(
        `${environment.baseUrl}v1/logging/updateCase`,
        { ...data },
        { headers }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  // logEvent(body: object) {
  //   const headers = { skip_interceptor: "true" };
  //   return this.http.post(`${environment.baseUrl}logEvent`, body, { headers });
  // }

  logEvent(body: object): Observable<any> {
    let observable: Observable<any>;
    if (this.cache) {
      observable = of(this.cache);
    } else if (this.cachedObservable) {
      observable = this.cachedObservable;
    } else {
      var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
      const headers = new HttpHeaders()
        .append('skip_interceptor', 'true')
        .append("appname", environment.appName)
        .append("Authorization", "Bearer " + idToken);
      this.cachedObservable = this.http
        .post(`${environment.baseUrl}v1/logging/logEvent`, body, { headers })
        .pipe(
          tap((res) => (this.cache = res)),
          share(),
          finalize(() => (this.cachedObservable = null))
        );
      observable = this.cachedObservable;
    }
    return observable;
  }

  getPreDrawEstimates(
    cardId: string,
    amount: string,
    caseId: string
  ): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "false")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getPreDrawEstimates/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getPreDrawEstimatesForOverLimit(
    cardId: string,
    amount: string,
    caseId: undefined | string
  ): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "false")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getPreDrawEstimatesForOverLimit/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  postCashDrawReject(
    cardId: string,
    amount: string,
    caseId: string
  ): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "true")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/postCashDrawReject/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  revenuedTest(): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "true")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/revenuedTest`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  requestPrepay(
    achDate: string,
    cardId: string,
    amount: string,
    caseId: string
  ): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "false")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
      .append("cardId", cardId)
      .append("achDate", achDate)
      .append("caseId", caseId)
      .append("amount", amount);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/requestPrepay/${oppId}`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  getClientAccessToken(cardId: string): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams().append("cardId", cardId);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/clientAccessToken`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getRevenuedActivateCard(cardId: string): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams().append("cardId", cardId);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/RevenuedActivateCard/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getRevenuedSuspendCard(cardId: string): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams().append("cardId", cardId);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/RevenuedSuspendCard`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  createMarketplaceEvent(data): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http.post<{ results: any[] }>(`${environment.baseUrl}v1/revenued/createMarketplaceEvent`, { ...data }, { headers }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getEnvVariables(): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
    return this.http.get<{ results: any[] }>('/api/env', { params, headers }).pipe(
      catchError(this.handleError)
    );
  }

  createCoverDashConversionEvent(data): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http.post<{ results: any[] }>(`${environment.baseUrl}v1/revenued/createCoverDashConversionEvent`, { ...data }, { headers }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getCoverDashMarketPlaceList(businessId): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getCoverDashPolicies`,
        { businessId },
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  createCoverDashApplication(data): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/createCoverDashApplication`,
        data,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getMarketPlaceList(): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getMarketPlaceList`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getRevenuedCards(): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/RevenuedGetCards/${oppId}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  getQuickViewNotifications(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;

    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getQuickViewNotifications/${oppId}`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  getUnreadFlyNotifications(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getUnreadFlyNotifications/${oppId}`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  getAllNotifications(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getAllNotifications/${oppId}`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }
  getScoreResponseByOppId_old(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'POST')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams()
    return this.http.post<{ results: any[] }>(`${environment.baseUrl}v1/submission/getScoreResponseByOppId/${oppId}`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  getScoreResponseByOppId(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .append('appname',environment.appName)
      // .append("skip_interceptor", "true")
      .append('Authorization', 'Bearer ' + idToken);
    const data = {
      oppId: oppId,
      loginDate: moment().format("YYYY-MM-DD")
    }
    return this.http.post<{ results: any[] }>(`${environment.baseUrl}v1/submission/getScoreResponseByOppId`, { ...data }, { headers }).pipe(
      catchError(this.handleError.bind(this))
    );

  }

  getAccountSettingPermissions(): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "true")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams().append("oppId", oppId);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/getAccountSettingPermissions`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  // markAsRead
  markNotificationRead(id: string): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    var oppId = window.localStorage.getItem('oppId');
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/markAsRead/${oppId}/${id}`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }
  setFirstLogin(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .append('appname',environment.appName)
      // .append("skip_interceptor", "true")
      .append('Authorization', 'Bearer ' + idToken);
    const data = {
      oppId: oppId,
      loginDate: moment().format("YYYY-MM-DD")
    }
    return this.http.post<{ results: any[] }>(`${environment.baseUrl}v1/revenued/setFirstLogin`, { ...data }, { headers }).pipe(
      catchError(this.handleError.bind(this))
    );

  }

  needsBankRelink(): Observable<any> {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append("skip_interceptor", "true")
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams().append("oppId", oppId);
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/needsBankRelink`, {
        params,
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  getTermAndFactorDirect(oppId): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      // .append('appname',environment.appName)
      .append('Authorization', 'Bearer ');
    let params = new HttpParams()
      .append('oppId', oppId)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getDirectTermAndFactor`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  getTermAndFactor(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
      .append('oppId', oppId)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getTermAndFactor`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  hasKYCStips(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
      .append('oppId', oppId)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/hasKYCStips`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  gettRewardsTerms(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
      .append('oppId', oppId)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/gettRewardsTerms`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  getRewards(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
      .append('oppId', oppId)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getRewards`, { params, headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  redeemRewards(caseId, amount): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'POST')
      // .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
      .append('oppId', oppId)
      .append('caseId', caseId)
      .append('amount', amount)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/redeemRewards`, { headers, params, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }


  getTransactionDetail(tid): Observable<any> {
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
      .set("Content-Type", "text/xml")
      // .append('skip_interceptor', 'true')
      .append("Access-Control-Allow-Methods", "GET")
      .append("Access-Control-Allow-Origin", "*")
      .append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
      )
      .append("appname", environment.appName)
      .append("Authorization", "Bearer " + idToken);
    let params = new HttpParams();
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .get<{ results: any[] }>(
        `${environment.baseUrl}v1/revenued/transactiondetail/${tid}`,
        { params, headers, responseType: "text" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  setDunsToOppId(data: any): any {
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/authentication/updateDuns?accId=${data.accId}&duns=${data.dunsId}&email=${data.email}`, {
      headers: {
        appname: data.appname || 'RevenuedUAT'
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  updateMetadata(data) {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
      skip_interceptor: "true",
    };
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post<{ results: any[] }>(`${environment.baseUrl}v1/authentication/updateUserMetaData`, data, {
        headers,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  verifyBusiness(data: any): any {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = JSON.parse(window.localStorage.getItem('token')).id_token;
    const headers = new HttpHeaders()
      .append('appname',environment.appName)
      .append("skip_interceptor", "true")
      .append('Authorization', 'Bearer ' + idToken);
    return this.http.post<{ results: any[] }>(`${environment.baseUrl}v1/submission/searchBusiness`, { ...data }, { headers }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getOppDetails(): Observable<any> {
    var oppId = window.localStorage.getItem('oppId');
    var idToken = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')).id_token : '';
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      .append("skip_interceptor", "true")
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname',environment.appName)
      .append('Authorization', 'Bearer ' + idToken);
    let params = new HttpParams()
      .append('oppId', oppId)
    return this.http.get<{ results: any[] }>(`${environment.baseUrl}v1/revenued/getOppDetails`, { params, headers, responseType: 'text' as 'json' }).pipe(
      tap((oppDetails: any) => {
        oppDetails = JSON.parse(oppDetails);
        this.hideRewards = oppDetails?.hideRewards;
      }),
      catchError(this.handleError)
    );
  }

  logoutUser(): Observable<any> {
    var idToken = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')).id_token : null;
    var accessToken = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')).access_token : null;
    if (idToken || accessToken) {
      const headers = new HttpHeaders()
        .set('Content-Type', 'text/xml')
        .append('appname', environment.appName)
        .append("skip_interceptor", "true")
        .append('Authorization', 'Bearer ' + idToken)
        .append('access_token', accessToken)
        .append('id_token', idToken);
      return this.http.post(`${environment.baseUrl}v1/authentication/logoutUser`, '', { headers });

    }
   }

   acceptOffer(oppId: string, offerId: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
      .append('skip_interceptor', 'true')
      .append('Accept', 'application/json, text/plain, */*')
      .append('appname', 'RevenuedUAT');

    const params = new HttpParams()
      .append('oppId', oppId)
      .append('offerId', offerId);

    return this.http.post(`${environment.baseUrl}v1/revenued/acceptOffer`, body, { params, headers })
      .pipe(catchError(this.handleError));
  }
}
